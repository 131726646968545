var Album = {

    removeImage: function(url) {
        $.get(url, function() {
            window.location.reload();
        });
    },

    initUploader: function() {
        Dropzone.options.dropzone = {
            maxFilesize: 10,
            acceptedFiles: 'image/*',
            init: function () {
                this.on("complete", function (file) {
                    if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0) {
                        window.location.reload();
                    }
                });
            }
        };

        $('#sortable-group').sortable({
            cursorAt: { top: 0, left: 0 },
            update: function (event, ui) {
                $.ajax({
                    data: $(this).sortable('serialize'),
                    type: 'POST',
                    url: ROOT+'owner/object/save-photo-order'
                });
            }
        });
    }
}