$(document).ready(function () {

    $('.filteraction').click(function (e) {
        $('.filters').slideToggle();
    });

    $("#carousel").owlCarousel({

        autoPlay: 3000,
        items: 4,
        itemsDesktop: [1199, 3],
        itemsDesktopSmall: [979, 3],
        navigation: true,
        navigationText: ["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"],
        pagination: false
    });

    $("#brands").owlCarousel({
        autoPlay: 3000,
        items: 5,
        itemsDesktop: [1199, 4],
        itemsDesktopSmall: [979, 3],
        itemsMobile: [479, 2],
        navigation: false,
        navigationText: false,
        pagination: false
    });

    var baseValue = $('.navbar').height();

    $('#toggle-button-nav').on('click', function () {
        $('.main-menu').addClass('hidden-menu');
        if(!baseValue) {
            baseValue = $('.navbar').height();
        }
        setTimeout(function () {
            var heightDiff = $('#main-nav > ul.nav.navbar-nav.main-menu.pull-right').height();
            $('.navbar').animate({height:baseValue+heightDiff}, 300, 'swing', function() {
                $('.main-menu').removeClass('hidden-menu', 300);    
            });
                
        }, 500);
    });

    $(function () {
        $('.krown-id-item').matchHeight();
        $('.height').matchHeight();
        $('.content-half').matchHeight();
    });

    var thumbs = jQuery('#thumbnails').slippry({
        slippryWrapper: '<div class="slippry_box thumbnails" />',
        // options
        transition: 'horizontal',
        pager: false,
        continuous: true,
        loop: true,
        auto: true,
        onSlideBefore: function (el, index_old, index_new) {
            jQuery('.thumbs a img').removeClass('active');
            jQuery('img', jQuery('.thumbs a')[index_new]).addClass('active');
        }
    });
    $('.thumbs a').click(function () {
        thumbs.goToSlide($(this).data('slide'));
        return false;
    });

    var thumbs2 = jQuery('#thumbnails2').slippry({
        slippryWrapper: '<div class="slippry_box thumbnails" />',
        // options
        transition: 'horizontal',
        pager: false,
        continuous: true,
        loop: true,
        auto: true,
        onSlideBefore: function (el, index_old, index_new) {
            jQuery('.thumbs a img').removeClass('active');
            jQuery('img', jQuery('.thumbs a')[index_new]).addClass('active');
        }
    });


    $('a[href*=#]:not([href=#])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top + -120
                }, 500);
                return false;
            }
        }
    });

    $(".form_datetime").datetimepicker({format: 'dd-mm-yyyy hh:ii'});
    $('.select-chosen').chosen({width: "100%"});

    if ($(window).width() > 844) {
        $.stickysidebarscroll("#sticky",{offset: {top: 170, bottom: 1040}});
    };

    $.ajaxSetup({
        headers: {'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')}
    });
});

function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name, default_value) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return default_value;
}
