var GoogleMaps = function (icon_prefix) {
    this.icon_prefix = icon_prefix
    this.markers = [];
    this.map = null;
    this.zoom = null;
};

GoogleMaps.prototype.setZoom = function (zoom) {
    this.zoom = zoom;
};

GoogleMaps.prototype.setObjects = function (objects) {
    this.objects = objects;
};

GoogleMaps.prototype.getObjects = function () {
    return this.objects;
};

GoogleMaps.prototype.getIcon = function () {
    return this.icon_prefix + '/maps_pointer_small.png';
};

GoogleMaps.prototype.getHoverIcon = function () {
    return this.icon_prefix + '/mappointer.png';
};

GoogleMaps.prototype.render = function (set_bounds, other_element) {
    var self = this;
    var bounds = new google.maps.LatLngBounds();

    var map_string = 'map';
    if(other_element !== undefined) {
        map_string = other_element;
    }
    
    var map = this.map = new google.maps.Map(document.getElementById(map_string), {
        zoom: 4,
        draggable: true,
        center: {"lat": 52.2296302, "lng": 5.167385},
        scaleControl: true,
        styles: [
            {
                "featureType": "administrative",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative.neighborhood",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "stylers": [
                    {
                        "saturation": -100
                    }
                ]
            },
            {
                "featureType": "poi",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels",
                "stylers": [
                    {
                        "saturation": -90
                    },
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            }
        ]
    });
    $.each(this.getObjects(), function (i, object) {
        self.markers.push(new google.maps.Marker({
            position: object['location'],
            map: map,
            icon: self.getIcon(),
            object: object
        }));
        bounds.extend(new google.maps.LatLng(object['location']));
    });

    if (set_bounds) {
        map.fitBounds(bounds);
    }

    if (self.zoom != null) {
        map.setZoom(self.zoom);
    }

    this.setEvents();
};

GoogleMaps.prototype.setFocus = function (object_id) {
    var self = this;

    $.each(self.markers, function (i, marker) {
        if (marker['object']['id'] == object_id) {
            self.map.setCenter(marker.getPosition());
            marker.setIcon(self.getHoverIcon());
            google.maps.event.trigger(marker, 'click');
        } else {
            marker.setIcon(self.getIcon());
        }
    });
}

GoogleMaps.prototype.setQuickFocus = function (object_id) {
    var self = this;

    $.each(self.markers, function (i, marker) {
        if (marker['object']['id'] == object_id) {
            // self.map.setCenter(marker.getPosition());
            marker.setIcon(self.getHoverIcon());
            google.maps.event.trigger(marker, 'click');
        } else {
            marker.setIcon(self.getIcon());
        }
    });
}

GoogleMaps.prototype.setEvents = function () {
    var self = this;
    var prev_infowindow = false;

    $.each(this.markers, function (i, marker) {

        // Hover
        google.maps.event.addListener(marker, 'mouseover', function () {
            marker.setIcon(self.getHoverIcon());
        });
        google.maps.event.addListener(marker, 'mouseout', function () {
            marker.setIcon(self.getIcon());
        });

        // Info window
        var infowindow = new google.maps.InfoWindow({
            content: self.buildInfoWindowContent(marker.object)
        });

        marker.addListener('click', function () {
            if (prev_infowindow) {
                prev_infowindow.close();
            }
            infowindow.open(self.map, marker);
            prev_infowindow = infowindow;
        });
    })
};


GoogleMaps.prototype.buildInfoWindowContent = function (object) {
    var str = '<a href="' + object['url'] + '#object-title"><img class="object-img lazyload" data-src="' + object['image'] + '" /></a><br /><br /><a style="text-decoration: underline" href="' + object['url'] + '#object-title">' + object['address'] + ', ' + object['place'] + '</a><br /><span class="object-info">' + object['surface'];
    if (object['price'] != '') {
        str = str + ' &nbsp; | &nbsp; ' + object['price'];
    }
    str = str + '</span>';
    return str;
};
